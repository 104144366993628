<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.51143 2.43843C4.44634 2.58159 3.80378 2.85387 3.32836 3.32854C2.85388 3.80347 2.5816 4.44588 2.43843 5.51111C2.29283 6.59449 2.2915 8.01801 2.2915 9.99984C2.2915 11.9817 2.29283 13.4054 2.43843 14.4888C2.58163 15.5543 2.85397 16.1962 3.32829 16.6702C3.80327 17.1452 4.44583 17.4179 5.51116 17.5611C6.59451 17.7068 8.018 17.7082 9.99984 17.7082C11.9817 17.7082 13.4054 17.7068 14.4887 17.5611C15.5541 17.4179 16.1961 17.1454 16.6701 16.6707C17.1449 16.1962 17.4179 15.5536 17.5611 14.4884C17.7068 13.4052 17.7082 11.9817 17.7082 9.99984C17.7082 8.018 17.7068 6.59472 17.5611 5.51148C17.4179 4.44643 17.1454 3.8039 16.6704 3.32845M16.6704 3.32845C16.1959 2.85405 15.5537 2.58161 14.4885 2.43843C13.4052 2.29283 11.9817 2.2915 9.99984 2.2915C8.01801 2.2915 6.5947 2.29283 5.51143 2.43843M9.95203 1.0415H10.0476C11.9713 1.04149 13.4788 1.04148 14.655 1.19957C15.8588 1.36138 16.8088 1.69905 17.5543 2.44456C18.3 3.19076 18.638 4.14086 18.8 5.34486C18.9582 6.52105 18.9582 8.02836 18.9582 9.95197V10.0477C18.9582 11.9713 18.9582 13.4788 18.8 14.655C18.6381 15.8588 18.3002 16.8087 17.5544 17.5541C16.8093 18.3002 15.8592 18.6381 14.6553 18.8C13.479 18.9582 11.9713 18.9582 10.0476 18.9582H9.95203C8.02839 18.9582 6.52085 18.9582 5.34455 18.8C4.14051 18.6381 3.19042 18.3001 2.44456 17.5543C1.69899 16.8091 1.36136 15.8591 1.19957 14.6553C1.04148 13.479 1.04149 11.9713 1.0415 10.0476V9.95204C1.04149 8.02838 1.04148 6.52088 1.19957 5.34461C1.36138 4.14064 1.69904 3.19059 2.44441 2.44471C3.19063 1.69951 4.14099 1.36139 5.34491 1.19957C6.52108 1.04148 8.02838 1.04149 9.95203 1.0415Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.3506 8.73516C12.7602 8.3256 13.4403 8.38514 13.7724 8.85965L17.1229 13.3037C17.2812 13.5299 17.2262 13.8416 17 14C16.7738 14.1584 16.462 14.1033 16.3037 13.8771L13 9.5L8.5605 14.2748C8.36524 14.4701 8.04865 14.4701 7.85339 14.2748C7.65813 14.0795 7.65813 13.763 7.85339 13.5677L8.64628 12.7748L6.85339 10.9819L4.0605 13.7748C3.86524 13.9701 3.54865 13.9701 3.35339 13.7748C3.15813 13.5795 3.15813 13.263 3.35339 13.0677L6.30288 10.1182C6.60692 9.81416 7.09986 9.81416 7.4039 10.1182L9.35339 12.0677L12.3506 8.73516Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.49984 7.49984C7.05212 7.49984 7.49984 7.05212 7.49984 6.49984C7.49984 5.94755 7.05212 5.49984 6.49984 5.49984C5.94755 5.49984 5.49984 5.94755 5.49984 6.49984C5.49984 7.05212 5.94755 7.49984 6.49984 7.49984ZM6.49984 7.99984C7.32826 7.99984 7.99984 7.32826 7.99984 6.49984C7.99984 5.67141 7.32826 4.99984 6.49984 4.99984C5.67141 4.99984 4.99984 5.67141 4.99984 6.49984C4.99984 7.32826 5.67141 7.99984 6.49984 7.99984Z"
    />
  </svg>
</template>
